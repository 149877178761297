import React from 'react';

import { DEFAULT_ICON_PROPS, IIconProps } from '../constants';

export const FacebookIcon = ({ ariaLabel = '', ...props }: IIconProps) => {
  const { size, width, height, title, ariaHidden, className } = {
    ...DEFAULT_ICON_PROPS,
    ...props,
  };
  return (
    <svg
      width={size ?? width}
      height={size ?? height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={ariaLabel}
      aria-hidden={ariaHidden}
      className={`block ${className}`}
      {...props}
    >
      {title && <title>{title}</title>}
      <path
        d="M12 0C5.373 0 0 5.373 0 12C0 18.016 4.432 22.984 10.206 23.852V15.18H7.237V12.026H10.206V9.927C10.206 6.452 11.899 4.927 14.787 4.927C16.17 4.927 16.902 5.03 17.248 5.076V7.829H15.278C14.052 7.829 13.624 8.992 13.624 10.302V12.026H17.217L16.73 15.18H13.624V23.877C19.481 23.083 24 18.075 24 12C24 5.373 18.627 0 12 0Z"
        className="fill-current"
      />
    </svg>
  );
};
