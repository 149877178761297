import React from 'react';

import { DEFAULT_ICON_PROPS, IIconProps } from '../constants';

export const TwitterIcon = ({ ariaLabel = '', ...props }: IIconProps) => {
  const { size, width, height, title, ariaHidden, className } = {
    ...DEFAULT_ICON_PROPS,
    ...props,
  };
  return (
    <svg
      width={size ?? width}
      height={size ?? height}
      viewBox="0 0 29 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={ariaLabel}
      aria-hidden={ariaHidden}
      className={`block ${className}`}
      {...props}
    >
      {title && <title>{title}</title>}
      <path
        d="M25.9538 6.03416C25.9714 6.28946 25.9714 6.54475 25.9714 6.8024C25.9714 14.653 20.0311 23.7071 9.16892 23.7071V23.7024C5.96021 23.7071 2.81815 22.7824 0.116943 21.0389C0.583516 21.0953 1.05243 21.1236 1.52251 21.1248C4.18162 21.1271 6.76473 20.2295 8.8567 18.5765C6.32972 18.5283 4.1138 16.8706 3.33968 14.4506C4.22489 14.6224 5.13698 14.5871 6.00581 14.3483C3.25081 13.7883 1.26876 11.353 1.26876 8.52475C1.26876 8.49887 1.26876 8.47416 1.26876 8.44946C2.08964 8.90946 3.00876 9.16475 3.94892 9.19299C1.35412 7.44828 0.554282 3.97534 2.12122 1.26005C5.11944 4.97181 9.54311 7.22828 14.2919 7.46711C13.8159 5.40358 14.4661 3.24122 16.0003 1.79063C18.3788 -0.458777 22.1195 -0.343483 24.3553 2.04828C25.6779 1.78593 26.9454 1.29769 28.1054 0.605929C27.6646 1.98122 26.742 3.14946 25.5095 3.89181C26.68 3.75299 27.8236 3.43769 28.9006 2.95652C28.1078 4.15181 27.1092 5.19299 25.9538 6.03416Z"
        className="fill-current"
      />
    </svg>
  );
};
