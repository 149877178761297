import React from 'react';

import { DEFAULT_ICON_PROPS, IIconProps } from '../constants';

export const EmailIcon = ({ ariaLabel = 'Email', ...props }: IIconProps) => {
  const { size, width, height, title, ariaHidden, className, stroke, strokeWidth } = {
    ...DEFAULT_ICON_PROPS,
    ...{ stroke: '#FFFFFF' },
    ...props,
  };
  return (
    <svg
      width={size ?? width}
      height={size ?? height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={ariaLabel}
      aria-hidden={ariaHidden}
      className={`block ${className}`}
      {...props}
    >
      {title && <title>{title}</title>}
      <path
        d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 8V13C16 13.7956 16.3161 14.5587 16.8787 15.1213C17.4413 15.6839 18.2043 16 19 16C19.7956 16 20.5587 15.6839 21.1213 15.1213C21.6839 14.5587 22 13.7956 22 13V12C21.9999 9.74303 21.2362 7.55247 19.8333 5.78453C18.4303 4.01658 16.4705 2.77522 14.2726 2.26229C12.0747 1.74937 9.76793 1.99504 7.72734 2.95936C5.68676 3.92369 4.03239 5.54995 3.03325 7.57372C2.03411 9.59749 1.74896 11.8997 2.22416 14.1061C2.69936 16.3125 3.90697 18.2932 5.65062 19.7263C7.39428 21.1593 9.57143 21.9603 11.8281 21.9991C14.0847 22.038 16.2881 21.3122 18.08 19.94"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
